'use strict'
const _ = require('lodash')
import moment from 'moment'
import * as dot from 'dot-object';
import scontriniService from './scontriniService'
import prodottiService from './prodottiService'

const service = process.env.VUE_APP_service_licenze || 'https://licenze.ubisell.ubi-co.com'

function getTotaleProdotti(r) {
  let qta = 0
  let prezzo = 0
  for (let i = r.length - 1; i >= 0; i--) {      
    let riga = r[i]
    if (['prodotto', 'reparto'].includes(riga.tipo) && parseFloat(riga.quantita || 0) > 0) {
      let sconto = 0
      let abbuono = 0          
      if (riga.sconto) {
        sconto += parseFloat(riga.sconto.prezzo)
      }
      if (riga.abbuono) {
        abbuono += parseFloat(riga.abbuono.prezzo)
      }
      qta += parseFloat(riga.quantita)
      prezzo += (parseFloat(riga.prezzo) + sconto + abbuono)
    }
  }
  return { qta, totale: prezzo}
}

function getScontiScontrino(r) {
  let scontoSub = 0
  let maggiorazioniSub = 0
  let locSub = 0
  let item = r.find(x => x.tipo === 'scontoSub')
  scontoSub += item ? parseFloat(item.valore) : 0
  locSub += item ? parseFloat(item.valore) : 0

  item = r.find(x => x.tipo === 'abbuonoSub')
  scontoSub += item ? parseFloat(item.valore) : 0
  locSub += item ? parseFloat(item.valore) : 0

  item = r.find(x => x.tipo === 'arrotondaDifetto')
  scontoSub += item ? -parseFloat(item.valore) : 0
  locSub += item ? parseFloat(item.valore) : 0

  item = r.find(x => x.tipo === 'arrotondaEccesso')
  maggiorazioniSub += item ? parseFloat(item.valore) : 0
  locSub += item ? parseFloat(item.valore) : 0

  item = r.find(x => x.tipo === 'subtotale')
  let subtotale = item ? parseFloat(item.prezzo) : 0
  if (subtotale === 0 && locSub !== 0) {
    subtotale = getTotaleProdotti(r).totale
  }
  const abbuonoPerEuro = subtotale > 0 ? (locSub) / subtotale : 0
  return {scontoSub, maggiorazioniSub, abbuonoPerEuro}
}

async function flatRigheScontini(base, filtro) {
  let statistica = []
  const start = moment(filtro.start, 'YYYYMMDDHHmmss').toISOString()
  const end = moment(filtro.end, 'YYYYMMDDHHmmss').toISOString()
  let scontrini = await scontriniService.elencoScontriniPerData(base, start, end, -1) // nessun limite sul numero degli scontrini
  scontrini = scontrini.filter(x => x.stato === 'chiuso') // tolgo scontrini annullati e aperti
  let sortScontrini = _.sortBy(scontrini, [function(o) { return o._if; }]).reverse()
  for (let s of sortScontrini) {
    let r = s.righe
    const {scontoSub, maggiorazioniSub, abbuonoPerEuro} = getScontiScontrino(r)

    for (let i = r.length - 1; i >= 0; i--) {      
      let riga = r[i]
      if (['prodotto', 'reparto'].includes(riga.tipo) /* && parseFloat(riga.quantita || 0) > 0 */) {
        let sconto = 0
        let abbuono = 0          
        if (riga.sconto) {
          sconto += parseFloat(riga.sconto.prezzo)
          delete riga.sconto
        }
        if (riga.abbuono) {
          abbuono += parseFloat(riga.abbuono.prezzo)
          delete riga.abbuono
        }
        let qta = parseFloat(riga.quantita)
        let prezzo = parseFloat(riga.prezzo) + sconto + abbuono
        prezzo = prezzo * (1 + abbuonoPerEuro)

        const stat = {
          data: s._id,
          quantita: qta,
          codice: riga.codice,
          valore: prezzo 
        }
        statistica.push(stat)          
      }
    }
  }
  return statistica
}

const dashboardVendutoPeriodoV2 = async function(base, filtro, tipoRiepilogo, tipoDashboard) {
  try {
    let lenChiave = 10
    if (tipoRiepilogo === 'year') { // mettere switch se più opzioni
      lenChiave = 7
    }

    let statistica = await flatRigheScontini(base, filtro)
    let risultato = _.reduce(statistica, function(result, value, key, collection) {
      let chiave = value.data.substr(0, lenChiave)
      let prec = result[chiave] || { k: chiave, q: 0, v: 0 }
      prec.q += value.quantita
      prec.v = +(prec.v + value.valore).toFixed(2)
      result[chiave] = prec 
      return result
    }, {})
    return Object.values(risultato)
  } catch (error) {
    // winston.error(`dashboardVendutoPeriodo_v2 - Errore: ${error}`);
  }
}

const dashboardVendutoPeriodoGruppoV2 = async function(base, filtro, tipoRiepilogo, tipoDashboard, gruppo) {
  try {
/*     const causali = await configurazioni.getConfigurazione("magazzino_causali", "causali");
    // Inserendo il tag dashboard nella causale, posso scegliere quale causale fare vedere nella dashboard. Se necessario anche array di dashboard per ogni causale
    let causaliValide = causali.filter(x => (tipoDashboard && x.dashboard === tipoDashboard) || x.tipo === 'vendita')
    causaliValide = causaliValide.map(x => x.codice) */
    let lenChiave = 10
    if (tipoRiepilogo === 'year') { // mettere switch se più opzioni
      lenChiave = 7
    }

    let statistica = await flatRigheScontini(base, filtro)

    let ids = _.uniq(statistica.map(x => x.codice))
    let prodotti = []
    let start = 0
    let count = 1000
    let end = count
    do {
      const toRead = ids.slice(start, end)
      const newProd = await prodottiService.getProdottiByIds(base, toRead)
      prodotti = _.concat(prodotti, newProd)
      start = end
      end = start + count
    } while (start < ids.length)

    let sortScontrini = _.sortBy(statistica, [function(o) { return o.data; }])
    let risultato = _.reduce(sortScontrini, function(result, value, key, collection) {
      let prodotto = prodotti.find(x => x._id === value.codice)
      let chiave = value.data.substr(0, lenChiave)
      if (prodotto) {
        chiave = (dot.pick(gruppo, prodotto) || '').toUpperCase()
        if (!chiave) {
          chiave = prodotto.descrizione.toUpperCase() // 'NON DEFINITO'
        }
        chiave = String(chiave)
      } else {
        chiave = 'NON DEFINITO'
      }

      let prec = result[chiave] || { k: chiave, q: 0, v: 0 }
    
      prec.q += value.quantita
      prec.v = +(prec.v + value.valore).toFixed(2)
      result[chiave] = prec 
      return result
    }, {})
    return _.sortBy(Object.values(risultato), [function(o) { return o.v; }]).reverse()
  } catch (error) {
    // winston.error(`dashboardVendutoPeriodoGruppo_v2 - Errore: ${error}`);
  }
}

const dashboardVendutoUltimo = async function(base, filtro, tipoRiepilogo, tipoDashboard) {
  try {
    let lenChiave = 10
    if (tipoRiepilogo === 'year') { // mettere switch se più opzioni
      lenChiave = 7
    }
    let statistica = await flatRigheScontini(base, filtro)
    let sortStatistica = _.sortBy(statistica, [function(o) { return o.data; }])
    let lastM = _.last(sortStatistica)
    let risultato = {
        k: lastM.data.substr(0, lenChiave),
        q: lastM.quantita,
        v: lastM.valore,
        d: lastM.data.substr(0, 19)
      }
    return risultato
  } catch (error) {
    // winston.error(`dashboardVendutoUltimo - Errore: ${error}`);
  }
}

export default {
  dashboardVendutoUltimo,
  dashboardVendutoPeriodoV2,
  dashboardVendutoPeriodoGruppoV2
}