<template>
  <v-card class="mx-auto" max-width="480" min-width="480" min-height="310" outlined :loading="loading">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="3" indeterminate></v-progress-linear>
    </template>
    <v-list-item three-line >
      <v-list-item-content>
        <div class="text-overline text-center">{{labelCategoria}}</div>
        <v-list-item-subtitle style="min-height: 32px;">
          <v-row no-gutters>
            <v-col><v-switch v-if="labelSwitchPeriodo" v-model="ultimoPeriodo" :label="labelSwitchPeriodo" class="ml-3" dense @change="onAggiorna"></v-switch></v-col>
            <v-col><v-select v-if="sceltaPeriodo" :items="tipiPeriodo" v-model="periodo" label="Periodo" class="pt-3" dense @change="onAggiorna"></v-select></v-col>
          </v-row>
        </v-list-item-subtitle>
        <v-list-item-title>
          <v-sheet class="stackSheet" color="white">
            <template v-if="sceltaRaggruppamento">
              <v-sparkline
                v-if="sparkValore"
                :gradient="['#1feaea', '#ffd200', '#f72047']"
                :value="valore"
                :labels="labelSparkLine"
                color="blue"
                line-width="10"
                smooth="0"
                padding="16"
                auto-draw
                type="bar"
              ></v-sparkline>                
              <v-sparkline
                v-if="sparkQuantita"
                :class="{ 'stackSpark': sparkValore }"
                color="red"
                line-width="2"
                smooth="8"
                padding="16"
                :value="quantita"
                auto-draw
              ></v-sparkline>
            </template>
            <template v-else>
              <v-sparkline
                v-if="sparkValore"
                :gradient="['#1feaea', '#ffd200', '#f72047']"
                :value="valore"
                :labels="labelSparkLine"
                color="blue"
                line-width="2"
                smooth="8"
                padding="16"
                auto-draw
              ></v-sparkline>                
              <v-sparkline
                v-if="sparkQuantita"
                :class="{ 'stackSpark': sparkValore }"
                color="red"
                line-width="2"
                padding="16"
                smooth="8"
                :value="quantita"
                :labels="labelSparkLine"
                auto-draw
              ></v-sparkline>
            </template>
          </v-sheet>               
        </v-list-item-title>
        <v-list-item-content>
          <v-simple-table dense fixed-header :height="espanso ? '' : '300px'" style="min-height: 300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{raggruppamento || label}}</th>
                  <th class="text-right">
                    Pezzi
                    <v-icon small color="red" @click="sparkQuantita = !sparkQuantita" title="Grafico andamento">mdi-chart-line</v-icon>
                  </th>
                  <th class="text-right">
                    Valore €
                    <v-icon small color="" @click="sparkValore = !sparkValore" title="Grafico andamento">mdi-chart-line</v-icon>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in dati" :key="item.data">
                  <td>{{ item.data }}</td>
                  <td class="text-right">{{ item.quantita }}</td>
                  <td class="text-right">{{ item.valore }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="text-left">Totale</th>
                  <th class="text-right">{{ sum.quantita }}</th>
                  <th class="text-right">{{ sum.valore }}</th>
                </tr>
              </tfoot>              
            </template>
          </v-simple-table>
        </v-list-item-content>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions class="d-flex justify-space-around">
        <v-badge bordered color="error" icon="mdi-timer-outline" overlap :value="timerOn">
          <v-btn outlined text @click="onAggiorna">Aggiorna<v-icon right>mdi-refresh</v-icon></v-btn>
        </v-badge>
        <v-btn outlined text @click="onEspandi">{{ espanso ? 'Contrai' : 'Espandi' }}<v-icon right>mdi-unfold-more-horizontal</v-icon></v-btn>
    </v-card-actions>
    <v-card-text v-if="sceltaRaggruppamento" class="pt-0 pb-0">  
      <v-divider class="my-4"></v-divider>
      <v-select :items="tipiRaggruppamento" v-model="raggruppamento" label="Raggruppamento" dense @change="onAggiorna"></v-select>
    </v-card-text>
    <v-card-text v-else class="pt-0">  
      <v-divider class="my-5"></v-divider>
      <v-icon class="mr-2" small :color="lastElement ? '' : 'white'">mdi-clock-outline</v-icon>
      <span class="text-caption grey--text font-weight-light">{{ ultimoAcquisto }}</span>
    </v-card-text>    
  </v-card>
</template>

<script>
import moment from 'moment'
import analisi from '@/services/analisiService'

export default {
  components: {
  },
  props: {
    item: Object,
    base: String
  },
  data(vm) {
    return {
      loading: false,
      dati: [],
      sum: {},
      ultimoPeriodo: true,
      labels: [],
      quantita: [],
      valore: [],
      espanso: false,
      timerOn: false,
      intervalAggiornamento: null,
      sparkValore: true,
      sparkQuantita: false,
      ultimoMovimento: null,
/*       tipiRaggruppamento: ['Marchio', 'Categoria1', 'Taglia', 'Colore'],
      tipiRaggruppamentoVariabili: ['marchio', 'statistici.categoria1.descrizione', 'varianti.variante1.descrizione', 'varianti.variante2.descrizione'], */
      raggruppamento: '',
      tipiPeriodo: ['giorno', 'settimana', 'mese', 'anno'],
      tipiPeriodoCalcolo: ['day', 'week', 'month', 'year'],
      periodo: 'settimana',      
      switchLabelPeriodo: ['', 'ultimi 7 giorni', 'ultimi 30 giorni', 'ultimi 12 mesi'],
      intervalloAggiornamentoMinuti: 5
    }
  },
  watch: {
    periodo(value) {
      if (['giorno', 'settimana', 'mese'].includes(this.periodo)) {
        if (!this.intervalAggiornamento) {
          this.intervalAggiornamento = setInterval(() => {
            this.onAggiorna()
          }, this.intervalloAggiornamentoMinuti * 60 * 1000)
          this.timerOn = true
        }
      } else {
        if (this.intervalAggiornamento) {
          clearInterval(this.intervalAggiornamento)
          this.intervalAggiornamento = null
          this.timerOn = false
        }
      }
    }
  },
  mounted() {
    setTimeout(async () => {
      this.raggruppamento = this.label
      this.timerOn = this.avviaTimer
      await this.$nextTick()
      await this.onAggiorna()
    }, Math.random() * 1000);
    if (this.avviaTimer || (this.sceltaPeriodo && ['giorno', 'settimana', 'mese'].includes(this.periodo))) {
      this.timerOn = true
      this.intervalAggiornamento = setInterval(() => {
        this.onAggiorna()
      }, this.intervalloAggiornamentoMinuti * 60 * 1000);  
    }
  },
  beforeDestroy() {
    if (this.intervalAggiornamento) {
      clearInterval(this.intervalAggiornamento)
      this.intervalAggiornamento = null
    }
  },
  computed:{
    // *******************************************************************************
    // *******************************************************************************
    // *******************************************************************************    
    id() { return this.item && this.item.id },
    categoria() { return this.item && this.item.categoria },
    tipo() { return this.item && this.item.tipo },
    label() { return (this.item && this.item.label) || '' },
    switchLabel() { return this.item && this.item.switchLabel },
    descrizione() { return this.item && this.item.descrizione },
    tipoDashboard() { return this.item && this.item.tipoDashboard },
    avviaTimer() { return (this.item && this.item.avviaTimer) || false },
    lastElement() { return (this.item && this.item.lastElement) || false },
    sceltaRaggruppamento() { return (this.item && this.item.sceltaRaggruppamento) || false },
    sceltaPeriodo() { return (this.item && this.item.sceltaPeriodo) || true },
    tipiRaggruppamento() { return (this.item && this.item.tipiRaggruppamento) || [] },
    tipiRaggruppamentoVariabili() { return (this.item && this.item.tipiRaggruppamentoVariabili) || [] },  
    // *******************************************************************************
    // *******************************************************************************
    // *******************************************************************************

    ultimoAcquisto() {
      return this.ultimoMovimento ? 'ultimo acquisto ' + moment(this.ultimoMovimento).fromNow() : ''
    },
    labelCategoria() {
      if (this.sceltaRaggruppamento) {
        return 'Venduto per ' + this.raggruppamento
      } else {
        return this.categoria
      }
    },
    labelSwitchPeriodo() {
      if (this.sceltaPeriodo) {
        const idx = this.tipiPeriodo.indexOf(this.periodo)
        return this.switchLabelPeriodo[idx]
      } else {
        return this.switchLabel
      }
    },
    labelSparkLine() {
      if (this.labels.length > 10 && !this.sceltaRaggruppamento) {
        const modulo = Math.ceil(this.labels.length / 10)
        return this.labels.map((x, idx) => { return idx % modulo === 0 ? x : ' '})
      } else {
        return this.labels
      }
    }
  },
  methods: {
    inizio(tipo) {
      if (!this.ultimoPeriodo) {
        return moment().startOf(tipo).format('YYYYMMDDHHmmss')
      } else {
        let offset = 7
        if (tipo === 'day') offset = 0
        if (tipo === 'month') offset = 30
        if (tipo === 'year') offset = 365
        return moment().add(-offset, 'day').startOf(tipo).format('YYYYMMDDHHmmss')
      }
    },
    fine(tipo) {
      if (!this.ultimoPeriodo) {
        return moment().endOf(tipo).format('YYYYMMDDHHmmss')
      } else {
        return moment().endOf('day').format('YYYYMMDDHHmmss')
      }
    },
    onEspandi() {
      this.espanso = !this.espanso

    },
    async onAggiorna() {
      this.loading = true
      const idx = this.tipiPeriodo.indexOf(this.periodo)
      const periodoCalcolo = this.tipiPeriodoCalcolo[idx]
      const tipo = this.sceltaPeriodo ? periodoCalcolo : this.tipo
      const filtro = {
        start: this.inizio(tipo),
        end: this.fine(tipo)
      }
      var elenco
      if (this.sceltaRaggruppamento) {
        const idx = this.tipiRaggruppamento.indexOf(this.raggruppamento)
        const variabile = this.tipiRaggruppamentoVariabili[idx]
        elenco = await analisi.dashboardVendutoPeriodoGruppoV2(this.base, filtro, tipo, this.tipoDashboard, variabile)
      } else {
        elenco = await analisi.dashboardVendutoPeriodoV2(this.base, filtro, tipo, this.tipoDashboard)
      }
      // const elenco = await analisi.dashboardVendutoPeriodo(filtro, tipo, this.tipoDashboard)
      const rp = this.sceltaRaggruppamento ? '' : tipo
      this.sum.quantita = 0
      this.sum.valore = 0
      let format = ''
      if (!elenco) {
        this.loading = false
        return
      }
      this.dati = elenco.map(x => {
        this.sum.quantita += x.q
        this.sum.valore = +(this.sum.valore + x.v).toFixed(2)
        let data = ''
        switch(rp) {
          case 'day':
            data = moment(x.k).format('HH:00')
            format = 'HH:00'
            break;          
          case 'week':
            if (!this.ultimoPeriodo) {
              data = moment(x.k).format('dddd')
              format = 'dddd'
            } else {
              data = moment(x.k).format('ddd/DD')
              format = 'ddd/DD'
            }            
            break;
          case 'month':
            data = moment(x.k).format('DD/MM')
            format = 'DD/MM'
            break;
          case 'year':
            if (!this.ultimoPeriodo) {
              data = moment(x.k).format('MM')
              format = 'MM'
            } else {
              data = moment(x.k).format('MM/YY')
              format = 'MM/YY'
            }            
            break;
          default:
            data = x.k
        }
        return {
          data: this.sceltaRaggruppamento ? x.k : data,
          quantita: x.q,
          valore: x.v.toFixed(2)
        }
      })
      this.sum.valore = this.sum.valore.toFixed(2)
      if (this.sceltaRaggruppamento) {
        this.labels = this.dati.map(x => x.data && x.data.substring(0, 2) || '')
      } else {
        this.labels = this.dati.map(x => x.data) // this.dati.map(x => moment(x.k).format(format))
      }
      this.quantita = this.dati.map(x => x.quantita)
      this.valore = this.dati.map(x => parseFloat(x.valore))
      if (this.lastElement) {
        const um = await analisi.dashboardVendutoUltimo(this.base, filtro, tipo, this.tipoDashboard)
        if (um) {
          this.ultimoMovimento = um.d
        }
      }

      this.loading = false
    }
  }
}
</script>

<style lang="css" scoped>
  .stackSheet {
      position: relative;
  }
  .stackSpark {
      position: absolute;
      top: 0;
      left: 0;
  }
</style>
