'use strict'
// require('dotenv').config();
import PouchDB from 'pouchdb';
PouchDB.plugin(require('pouchdb-find')); // npm install --save pouchdb-find

const service = process.env.VUE_APP_service_licenze || ''

const getProdottiByIds = async (base, ids, limit) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'prodotti' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return

    let opz = {include_docs: true, keys: ids}
    if (limit > 0) {
      opz.limit = limit
    }
    let result = await db.allDocs(opz);
    result.rows = result.rows.filter(x => !x.error && !x.value.deleted)
    await db.close()
    const elenco = result.rows.map(x => x.doc).filter(x => x && !x._id.startsWith('_'));
    return elenco
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

const getFilteredProducts = async (base, filtri, opzioni) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'prodotti' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  const urlAuth = { urlDB, username, password }
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return

    let ids = []
    let filtroApplicato = false
    var pp
    if (filtri && filtri.barcode) {
      let pr = await prod_get_from_barcode_index (urlAuth, filtri.barcode)
      if (pr) {
        ids = filtroApplicato ? _.intersection(ids, [pr._id]) : [pr._id]
        filtroApplicato = true
      } else {
        return { total_rows: 0 }
      }
    }

    if (filtri && filtri.codice) {
      pp = await prod_search_by_code_description(urlAuth, { codice: filtri.codice }, (opzioni && opzioni.itemsPerPage) || 1000)
      if (pp.length > 0) {
        ids = filtroApplicato ? _.intersection(ids, pp.map(x => x._id)) : pp.map(x => x._id)
        filtroApplicato = true
      } else {
        return { total_rows: 0 }
      }
    }
 
    const opz = {
      itemsPerPage: opzioni && opzioni.itemsPerPage || 20,
      page: opzioni && opzioni.page || 1
    }
    const dbOptions = {
      include_docs: true,
      limit: opz.itemsPerPage,
      skip: (opz.page - 1) * opz.itemsPerPage
    }
    if (ids.length > 0) {
      dbOptions.keys = ids
    } else {
      if (filtroApplicato || (filtri && (filtri.codice || filtri.barcode))) {
        await db.close()
        return {
          rows: [],
          total_rows: 0
        }
      }
    }
    const prodotti = await db.allDocs(dbOptions);
    prodotti.total_rows = ids.length || prodotti.total_rows
    await db.close()
    return prodotti
  }
  catch (err) {
    console.log(err)
    await db.close()
    return err;
  }
}

const prod_get_from_barcode_index = async (urlAuth, barcode) => {
  try {
    const data = {
      selector: {
        'ricercaBarcode': {
          '$eq': barcode
        }
      },
      use_index: 'barcode-ricerca-index',
      fields: ['_id', 'codice', 'tipoBene', 'tipoItem', 'descrizione', 'varianti', 'ricercaTC']
    }
    const basicAuth = 'Basic ' + Buffer.from(`${urlAuth.username}:${urlAuth.password}`, 'utf-8').toString('base64')
    const response = await fetch(`${urlAuth.urlDB}/_find`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth
      },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    if (result.docs.length) return result.docs[0]
    return null // verificare era {}
  }
  catch (err) {
    console.log('errore search', err)
    return err;
  }
}

const prod_search_by_code_description = async (urlAuth, filtri, limit) => {
  // TODO: Va verificata la ricerca per Codice, probabiemente non funziona --> cambiare come barcode
  try {
/*     var ddoc_code = createDesignDoc('by_code_filter1', function (doc) {
      if (doc.codice) {
        emit(String(doc.codice).toLowerCase())
      }
    })
    var ddoc_description = createDesignDoc('by_description_filter1', function (doc) {
      if (doc.descrizione) {
        emit(String(doc.descrizione).toLowerCase())
      }
    })
    try {
      await db.put(ddoc_code)
    } catch (err) {
      if (err.name !== 'conflict') {
        console.log(err)
      }
    }
    try {
      await db.put(ddoc_description)
    } catch (err) {
      if (err.name !== 'conflict') {
        console.log(err)
      }
    } */

/*     let filtro = filtri.codice ? filtri.codice.toLowerCase() : (filtri.descrizione ? filtri.descrizione.toLowerCase() : '')
    let options = {
      startkey: filtro,
      endkey: filtro + '\ufff0',
      limit: limit || 10
    }
    let nomeFiltro = 'by_description_filter1'
    if (filtri.hasOwnProperty('codice')) {
      nomeFiltro = 'by_code_filter1'
    }
    debugger
    const data = {
      keys : ['viti01']
    } */
    const data = {
      selector: {
        'codice': {
          '$gte': filtri.codice.toLowerCase()
        }
      },
      use_index: 'prodotti-index-v2'
    }    
    const basicAuth = 'Basic ' + Buffer.from(`${urlAuth.username}:${urlAuth.password}`, 'utf-8').toString('base64')
    // const response = await fetch(`${urlAuth.urlDB}/_design/${nomeFiltro}/_view/${nomeFiltro}`, {
    const response = await fetch(`${urlAuth.urlDB}/_find`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth
      },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    if (result.docs.length) {
      return result.docs
    } else {
      return []
    }
  } catch (err) {
    console.log(err)
    return []
  }
}

export default {
  getProdottiByIds,
  getFilteredProducts
}