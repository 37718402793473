<template>
  <div>
    <v-container fluid dense>
      <v-row justify="space-around">
        <v-col v-for="(item, index) in dashboard" :key="index" xl="6" xm="12">
          <component :is="item.componente" :item="item" :base="base" :apikey="apikey"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  // import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import configurazioni from '@/services/configurazioniService'
  import SnippletTabella from './elementi/snippletTabella.vue'
  import Tabella from './elementi/tabella.vue'
  import TabellaDate from './elementi/tabellaDate.vue'
  import Grafico from './elementi/grafico.vue'
  import GraficoDate from './elementi/graficoDate.vue'

  export default {
    name: 'Dashboard',
    components: {
      SnippletTabella,
      Tabella,
      TabellaDate,
      Grafico,
      GraficoDate
    },
    // mixins: [verificaLicenza],
    data: () => ({
/*       tipiRaggruppamento: ['Marchio', 'Categoria1', 'Categoria2'], // TODO: prendere da db
      tipiRaggruppamentoVariabili: ['marchio', 'statistici.categoria1.descrizione', 'varianti.variante1.descrizione', 'varianti.variante2.descrizione'], */
      dashboard: null,
      base: '',
      apikey: ''
    }),
    computed: {
      device() {
        return this.$store.getters['mobile/device']
      }
    },
    watch: {
      device(value) {
        this.dashboard = value.dashboard
        this.base = value.base
        this.apikey = value.apiKey
      }
/*       setTimeout(async () => {
        await this.gestioneToken()
      }, 100) */
    },
    async mounted() {
      if (this.device) {
        this.base = this.device.base
        this.apikey = this.device.apiKey
        const platform = await configurazioni.getConfigurazione(this.base, 'mobile', 'data')
        if (platform) {
          this.dashboard = platform.dashboard
        }
      }
    },
    methods: {
    }
  }
</script>
