<template>
<v-card class="mx-auto" min-width="480" min-height="540" outlined :loading="loading">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="3" indeterminate></v-progress-linear>
    </template>
    <v-list-item three-line >
      <v-list-item-content>
        <div class="text-overline text-center">{{labelCategoria}}</div>
        <v-list-item-subtitle style="min-height: 32px;">
          <v-row class="pt-1" dense>
              <v-col>
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedStartFormatted"
                      label="Dalla data"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      hide-details
                      @click:clear="filtro.start = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filtro.start" no-title scrollable @input="menuStart = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedEndFormatted"
                      label="Alla data"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      hide-details
                      @click:clear="filtro.end = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filtro.end" no-title scrollable @input="menuEnd = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
        </v-list-item-subtitle>
        <v-list-item-title>
        </v-list-item-title>
        <v-list-item-content class="pa-10">
          <apexchart  :type="item.tipo" :options="chartOptions" :series="tipoSerie === 'quantita' ? seriesQ : seriesV"></apexchart>
        </v-list-item-content>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions class="d-flex justify-space-around">
      <v-badge bordered color="error" icon="mdi-timer-outline" overlap :value="timerOn">
        <v-btn outlined text @click="onAggiorna">Aggiorna<v-icon right>mdi-refresh</v-icon></v-btn>
      </v-badge>
      <v-btn outlined text @click="onEspandi">{{ espanso ? 'Contrai' : 'Espandi' }}<v-icon right>mdi-unfold-more-horizontal</v-icon></v-btn>
    </v-card-actions>
    <v-card-text v-if="sceltaRaggruppamento" class="pt-0 pb-0">  
      <v-divider class="my-4"></v-divider>
      <v-select :items="tipiRaggruppamento" v-model="raggruppamento" item-text="descrizione" item-value='codice'  label="Raggruppamento" return-object dense @change="onAggiorna"></v-select>
    </v-card-text>
  </v-card>    
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import collector from '@/services/analisi/collector'

export default {
  components: {
  },
  props: {
    item: Object,
    base: String,
    apikey: String
  },
  data() {
    return {
      filtro: {
        start: moment().utcOffset(0, true).format('YYYY-MM-DD'),
        end: moment().utcOffset(0, true).format('YYYY-MM-DD')
      },
      espanso: false,
      loading: false,
      dati: [],
      sum: {},
      ultimoPeriodo: true,
      raggruppamento: '',
      tipiPeriodo: ['giorno', 'settimana', 'mese', 'anno'],
      tipiPeriodoCalcolo: ['day', 'week', 'month', 'year'],
      periodo: 'settimana',    
      switchLabelPeriodo: ['', 'ultimi 7 giorni', 'ultimi 30 giorni', 'ultimi 12 mesi'],
      timerOn: false,
      intervalloAggiornamentoMinuti: 5,
      aggregati: [],
      series:[],
      menuStart: false,
      menuEnd: false      
    }
  },
  async mounted() {
    this.raggruppamento = this.tipiRaggruppamento[0]
    await this.$nextTick()
    await this.onAggiorna()
  },
  watch: {
    async 'filtro.start' (val) {
      this.startFormatted = this.formatDate(this.filtro.start)
      await this.onAggiorna()
    },
    async 'filtro.end' (val) {
      this.endFormatted = this.formatDate(this.filtro.end)
      await this.onAggiorna()
    }       
  },  
  computed: {
    // *******************************************************************************
    // *******************************************************************************
    // *******************************************************************************    
    id() { return this.item && this.item.id },
    categoria() { return this.item && this.item.categoria },
    tipo() { return this.item && this.item.tipo },
    label() { return (this.item && this.item.label) || '' },
    switchLabel() { return this.item && this.item.switchLabel },
    descrizione() { return this.item && this.item.descrizione },
    tipoDashboard() { return this.item && this.item.tipoDashboard },
    avviaTimer() { return (this.item && this.item.avviaTimer) || false },
    lastElement() { return (this.item && this.item.lastElement) || false },
    sceltaRaggruppamento() { return (this.item && this.item.sceltaRaggruppamento) || false },
    sceltaPeriodo() { return (this.item && this.item.sceltaPeriodo) || true },
    tipoSerie() { return (this.item && this.item.tipoSerie) || 'quantita' }, 
/*     tipiRaggruppamento() { return (this.item && this.item.tipiRaggruppamento) || [] },
    tipiRaggruppamentoVariabili() { return (this.item && this.item.tipiRaggruppamentoVariabili) || [] }, */
    // *******************************************************************************
    // *******************************************************************************
    // *******************************************************************************    
    labelSwitchPeriodo() {
      if (this.sceltaPeriodo) {
        const idx = this.tipiPeriodo.indexOf(this.periodo)
        return this.switchLabelPeriodo[idx]
      } else {
        return this.switchLabel
      }
    },
    labelCategoria() {
      if (this.raggruppamento && this.raggruppamento.codice !== 'nessuno') {
        return 'Venduto per ' + this.raggruppamento.descrizione
      } else {
        return this.categoria
      }
    },
    computedStartFormatted () {
      return this.formatDate(this.filtro.start)
    },
    computedEndFormatted () {
      return this.formatDate(this.filtro.end)
    },    
    seriesQ() {
      return this.aggregati.map(x => x.QtaMag)
    },
    seriesV() {
      return this.aggregati.map(x => x.PrezzoVendita)
    },
    seriesLabels() {
      return this.aggregati ? this.aggregati.map(x => x.raggruppamentoTesto) : []
    },
/*     seriesOptions() {
      const opt = this.chartOptions
      Object.assign(opt.labels, this.seriesLabels)
      return opt
    }, */
    chartOptions() {
      return {
        chart: {
          // width: 390,
          type: this.item.tipo
        },
        labels: this.seriesLabels
      }
    },
    headersColonne() {
      return this.headers ? this.headers.map(x => {
        return { value: x.codice, text: x.descrizione }
      }) : []
    },
    variabili() {
      return this.item.variabili
    },
    tipiRaggruppamento() { return (this.item && this.item.raggruppamenti) || [] }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      return moment(date).format('DD/MM/YYYY')
    },
    parseDate (date) {
      if (!date) return null
      return moment(date).format('YYYYMMDD')
    },    
/*     inizio(tipo) {
      if (!this.ultimoPeriodo) {
        return moment().startOf(tipo).format('YYYYMMDDHHmmss')
      } else {
        let offset = 7
        if (tipo === 'day') offset = 0
        if (tipo === 'month') offset = 30
        if (tipo === 'year') offset = 365
        return moment().add(-offset, 'day').startOf(tipo).format('YYYYMMDDHHmmss')
      }
    },
    fine(tipo) {
      if (!this.ultimoPeriodo) {
        return moment().endOf(tipo).format('YYYYMMDDHHmmss')
      } else {
        return moment().endOf('day').format('YYYYMMDDHHmmss')
      }
    },  */   
    onEspandi() {
      this.espanso = !this.espanso
    },
    async onAggiorna() {
      this.loading = true

      let filtro = { ...this.filtro }
      filtro.start = this.parseDate(filtro.start)
      filtro.end = this.parseDate(filtro.end)

/*       const idx = this.tipiPeriodo.indexOf(this.periodo)
      const periodoCalcolo = this.tipiPeriodoCalcolo[idx]
      const tipo = this.sceltaPeriodo ? periodoCalcolo : this.tipo
      let filtro = {
        start: this.inizio(tipo),
        end: this.fine(tipo)
      } */

      const variabili = this.item.variabili
      const gruppi = this.raggruppamento && this.raggruppamento.gruppi || []
      const result = await collector.getMovimentiReportGruppi(this.base, this.apikey, filtro, variabili, gruppi)
      if( gruppi.length > 0) {
        this.dati = result.riepilogo
        this.aggregati = result.riepilogo
      } else {
        this.dati = result.dati
      }
      
      // this.totali = result.totali

/*       const movimenti = await collector.getMovimentiReport(this.base, this.apikey, filtro)
      const tipi = this.tipiRaggruppamento 
      const rg = this.raggruppamento
      let rgIdx = tipi.findIndex(x => x === rg)
      let ragg = this.tipiRaggruppamentoVariabili[rgIdx]
      const rp = ragg ? '' : tipo
      const risultato = _.reduce(movimenti, function(result, value, key, collection) {
        let chiave = value[ragg]
        let prec = result[chiave] || { k: chiave, q: 0, v: 0 }
        prec.q += value.QtaMag
        prec.v = +(prec.v + value.PrezzoVendita).toFixed(2)
        result[chiave] = prec 
        return result
      }, {})
      if (risultato) {
        this.aggregati = Object.values(risultato)
      }    */   
      this.loading = false
    }
  }
}
</script>
