<template>
<v-card class="mx-auto" min-width="480" min-height="540" outlined :loading="loading">
<!--     <template slot="progress">
      <v-progress-linear color="deep-purple" height="3" indeterminate></v-progress-linear>
    </template> -->
    <v-data-table
      color="blue lighten-4"
      item-key="_id"
      :headers="headersColonne"
      :items="dati"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      }"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{labelCategoria}}</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
            <v-row class="pt-1" dense>
              <v-col>
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedStartFormatted"
                      label="Dalla data"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      hide-details
                      @click:clear="filtro.start = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filtro.start" no-title scrollable @input="menuStart = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedEndFormatted"
                      label="Alla data"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      hide-details
                      @click:clear="filtro.end = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filtro.end" no-title scrollable @input="menuEnd = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.DatMag`]="{ item }">
        {{ item.DatMag | date }}
      </template>
      <template v-slot:[`item.PrezzoVendita`]="{ item }">
        {{ item.PrezzoVendita | euro }}
      </template>
    </v-data-table>
    <v-card-actions class="d-flex justify-space-around">
      <v-col cols="6"><v-select :items="tipiRaggruppamento" v-model="raggruppamento" item-text="descrizione" item-value='codice'  label="Raggruppamento" return-object dense @change="onAggiorna"></v-select></v-col>
      <v-col></v-col>
      <v-col cols="3">
        <v-badge bordered color="error" icon="mdi-timer-outline" overlap :value="timerOn">
          <v-btn outlined text @click="onAggiorna">Aggiorna<v-icon right>mdi-refresh</v-icon></v-btn>
        </v-badge>
      </v-col>
      <v-col cols="3"><v-btn outlined text @click="onEspandi">{{ espanso ? 'Contrai' : 'Espandi' }}<v-icon right>mdi-unfold-more-horizontal</v-icon></v-btn></v-col>
    </v-card-actions>   
  </v-card>    
</template>

<script>
  import moment from 'moment'
  import collector from '@/services/analisi/collector'

  export default {
    components: {
    },
    props: {
      item: Object,
      base: String,
      apikey: String
    },
    data() {
      return {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,        
        options: {},              
        filtro: {
          start: moment().utcOffset(0, true).format('YYYY-MM-DD'),
          end: moment().utcOffset(0, true).format('YYYY-MM-DD')
        },
        espanso: false,
        loading: false,
        dati: [],
        totali: {},
        raggruppamento: '',
        timerOn: false,
        intervalloAggiornamentoMinuti: 5,
        menuStart: false,
        menuEnd: false
      }
    },
    async mounted() {
      await this.onAggiorna()
    },
    watch: {
      async 'filtro.start' (val) {
        this.startFormatted = this.formatDate(this.filtro.start)
        await this.onAggiorna()
      },
      async 'filtro.end' (val) {
        this.endFormatted = this.formatDate(this.filtro.end)
        await this.onAggiorna()
      }    
    },  
    computed: {
      // *******************************************************************************
      // *******************************************************************************
      // *******************************************************************************    
      id() { return this.item && this.item.id },
      categoria() { return this.item && this.item.categoria },
      tipo() { return this.item && this.item.tipo },
      label() { return (this.item && this.item.label) || '' },
      switchLabel() { return this.item && this.item.switchLabel },
      descrizione() { return this.item && this.item.descrizione },
      tipoDashboard() { return this.item && this.item.tipoDashboard },
      avviaTimer() { return (this.item && this.item.avviaTimer) || false },
      lastElement() { return (this.item && this.item.lastElement) || false },
      sceltaRaggruppamento() { return (this.item && this.item.sceltaRaggruppamento) || false },
      sceltaPeriodo() { return (this.item && this.item.sceltaPeriodo) || true },
      // tipiRaggruppamento() { return (this.item && this.item.tipiRaggruppamento) || [] },
      // tipiRaggruppamentoVariabili() { return (this.item && this.item.tipiRaggruppamentoVariabili) || [] },
      // *******************************************************************************
      // *******************************************************************************
      // *******************************************************************************    
      labelCategoria() {
        if (this.raggruppamento && this.raggruppamento.codice !== 'nessuno') {
          return 'Venduto per ' + this.raggruppamento.descrizione
        } else {
          return this.categoria
        }
      },
      computedStartFormatted () {
        return this.formatDate(this.filtro.start)
      },
      computedEndFormatted () {
        return this.formatDate(this.filtro.end)
      },
      headers() {
        return this.intestazioniColonneRaw(this.dati)
      },
      headersColonne() {
        return this.headers ? this.headers.map(x => {
          return { value: x.codice, text: x.descrizione }
        }) : []
      },      
      variabili() {
        return this.item.variabili
      },
      tipiRaggruppamento() { return (this.item && this.item.raggruppamenti) || [] }
    },
    methods: {
      getValue(item, codice) {
        const vr = this.variabili.find(x => x.codice === codice)
        const chiave = vr.codice
        let valore = item[chiave]
        if (vr.formato) {
          const fn = this.$options.filters[vr.formato]
          valore = fn(valore)
        }
        return valore
      },
      getTotale(codice) {
        // const vr = this.variabili[index]
        const vr = this.variabili.find(x => x.codice === codice)
        let valore = this.totali[codice] || ''
        if (valore !== '' && vr.formato) {
          const fn = this.$options.filters[vr.formato]
          valore = fn(valore)
        }
        return valore
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD/MM/YYYY')
      },
      parseDate (date) {
        if (!date) return null
        return moment(date).format('YYYYMMDD')
      },
      onEspandi() {
        this.espanso = !this.espanso
      },
      async onAggiorna() {
        this.loading = true
        let filtro = { ...this.filtro }
        filtro.start = this.parseDate(filtro.start)
        filtro.end = this.parseDate(filtro.end)
        // TODO: per debug, poi togliere la riga quando ci sono i dati corretti nel database
        // filtro = { start: moment().add(-20, 'y').format('yyyyMMDD'), end: moment().format('yyyyMMDD')}

        const variabili = this.item.variabili
        
        const gruppi = this.raggruppamento && this.raggruppamento.gruppi || []

        const result = await collector.getMovimentiReportGruppi(this.base, this.apikey, filtro, variabili, gruppi)
        if( gruppi.length > 0) {
          this.dati = result.riepilogo
        } else {
          this.dati = result.dati
        }
        
        this.totali = result.totali
        this.loading = false
      },
      intestazioniColonneRaw(dati) {
        if (!dati || dati.length === 0) {
          return []
        }
        const riga = dati[0]
        const keys = Object.keys(riga).filter(x => !['_id'].includes(x))
        const colonne = this.variabili.filter(x => keys.includes(x.codice))
        return colonne
      } 
    }
  }
</script>

<style lang="scss" scoped>
/*   th, td {
    width: 15%;
    &.w1 {
      width: 10%;
    }
    &.w2 {
      width: 20%;
    }
    &.w3 {
      width: 30%;
    } 
    &.w4 {
      width: 40%;
    }        
  } */
</style>