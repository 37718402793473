var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"min-width":"480","min-height":"540","outlined":"","loading":_vm.loading}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"color":"blue lighten-4","item-key":"_id","headers":_vm.headersColonne,"items":_vm.dati,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      },"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.labelCategoria))])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-1",attrs:{"dense":""}},[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedStartFormatted,"label":"Dalla data","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":"","hide-details":""},on:{"click:clear":function($event){_vm.filtro.start = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menuStart = false}},model:{value:(_vm.filtro.start),callback:function ($$v) {_vm.$set(_vm.filtro, "start", $$v)},expression:"filtro.start"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedEndFormatted,"label":"Alla data","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":"","hide-details":""},on:{"click:clear":function($event){_vm.filtro.end = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menuEnd = false}},model:{value:(_vm.filtro.end),callback:function ($$v) {_vm.$set(_vm.filtro, "end", $$v)},expression:"filtro.end"}})],1)],1)],1)],1)]},proxy:true},{key:"item.DatMag",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.DatMag))+" ")]}},{key:"item.PrezzoVendita",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euro")(item.PrezzoVendita))+" ")]}}],null,true)}),_c('v-card-actions',{staticClass:"d-flex justify-space-around"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.tipiRaggruppamento,"item-text":"descrizione","item-value":"codice","label":"Raggruppamento","return-object":"","dense":""},on:{"change":_vm.onAggiorna},model:{value:(_vm.raggruppamento),callback:function ($$v) {_vm.raggruppamento=$$v},expression:"raggruppamento"}})],1),_c('v-col'),_c('v-col',{attrs:{"cols":"3"}},[_c('v-badge',{attrs:{"bordered":"","color":"error","icon":"mdi-timer-outline","overlap":"","value":_vm.timerOn}},[_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":_vm.onAggiorna}},[_vm._v("Aggiorna"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":_vm.onEspandi}},[_vm._v(_vm._s(_vm.espanso ? 'Contrai' : 'Espandi')),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-unfold-more-horizontal")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }